<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': sidebars.leftSidebar}">
      <FilterQuickCalculation v-if="isLeftSidebarVisible" @slide="isLeftSidebarVisible = $event" />
    </SidebarLeft>

    <SidebarCenter>
      <div class="quick-calculation">
        <ZemButton>Рассчитать</ZemButton>
        <ZemCollapse is-copy is-delete title="Основания и покрытия">
          <template v-slot:header>
            <div class="zem-collapse__delete" @click="onDelete">
              <img :src="require('@/assets/icons/basket.svg')" alt="" />
            </div>
            <div class="zem-collapse__copy" @click="onCopy">
              <img :src="require('@/assets/icons/copy.svg')" alt="" />
            </div>
          </template>

          <zem-label>Площадь</zem-label>
          <zem-input class="w-50" is-compact></zem-input>
          <zem-label>Покрытия</zem-label>
          <zem-dropdown-list :options="[]" class="mt-0 w-50" placeholder="Выбрать" type="string"></zem-dropdown-list>
        </ZemCollapse>
      </div>
    </SidebarCenter>

    <SidebarRight :class="{'sidebar-right--show': sidebars.rightSidebar}">
      <!--            <Product/>-->
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import FilterProductsForm from '@/components/FilterProductsForm'
import Product from '@/components/Product'
import TableContainer from '@/components/TableContainer'
import {eventBus} from '@/main'
import {errorHandler} from '@/assets/scripts/scripts'
import ProductsService from '@/services/products.service'
import SidebarLeft from '@/components/SidebarLeft.vue'
import FilterQuickCalculation from '@/components/FilterQuickCalculation.vue'
import SidebarCenter from '@/components/SidebarCenter.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemCollapse from '@/components/ui/ZemCollapse.vue'
import ZemInput from '@/components/ui/ZemInput.vue'
import ZemLabel from '@/components/ui/ZemLabel.vue'
import ZemDropdownList from '@/components/ui/ZemDropdownList.vue'

export default {
  components: {
    ZemDropdownList,
    ZemLabel,
    ZemInput,
    ZemCollapse,
    ZemButton,
    SidebarCenter,
    FilterQuickCalculation,
    Product,
    SidebarLeft,
    SidebarRight,
    FilterProductsForm,
    TableContainer,
  },
  data() {
    return {
      isLeftSidebarVisible: true,
    }
  },

  computed: {
    searchCount() {
      return this.$store.state.products.searchCount
    },
    products() {
      return this.$store.state.products.elements
    },
    sidebars() {
      return this.$store.state.sidebars
    },
    tableData() {
      return [
        {
          label: this.$t('Article'),
          field: 'sku',
          sortField: 'sku',
          showInTable: true,
          sort: 0,
        },
        {
          label: this.$t('Name'),
          field: 'title',
          sortField: 'title',
          showInTable: true,
          sort: 0,
        },
        {
          label: this.$t('Units of measurement'),
          field: 'unit.data.name',
          sortField: 'unit',
          showInTable: true,
          sort: 0,
        },
      ]
    },
  },

  mounted() {
    const width = window.innerWidth
    this.$store.commit('sidebars/changeLeftSidebar', width > 768)
    this.$store.commit('sidebars/changeRightSidebar', false)
  },

  methods: {
    onDelete() {
      alert('Delete')
    },
    onCopy() {
      alert('Copy')
    },
    updateData() {
      ProductsService.getDirectoryItems()
    },
    selectItem(data) {
      if (data.column.field !== 'checkbox') {
        this.$store.commit('sidebars/changeRightSidebar', true)
        eventBus.$emit('update-schema', data.row)
      }
    },
    deleteItem() {
      this.$refs['table-container'].loadingDelete = true
      ProductsService.deleteDirectoryItems()
        .then(() => {
          this.$toast.success(this.$t('The list item is removed'))
          this.$refs['table-container'].loadingDelete = false
          this.$refs['table-container'].showDeleteModal = false
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('products/itemsForDelete', [])
          eventBus.$emit('new-state', false)
          ProductsService.getDirectoryItems()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
  },
  beforeDestroy() {
    this.$store.commit('products/updateElements', {data: []})
    this.$store.commit('products/updatePagination', {
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.quick-calculation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
